var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _AnimeWatchModalController_handlebars, _AnimeWatchModalController_animeId, _AnimeWatchModalController_animeTitle, _AnimeWatchModalController_isLoading, _AnimeWatchModalController_availabilityPreference, _a, _instances, _currentCategory, _showCategories, _b;
import HandlebarsCompilerFactory from '@root/javascript/handlebars/compiler-factory';
import { application, Controller } from '@local-stimulus';
import graphqlClient, { gql } from '@root/javascript/graphql/client';
import HideUnavailablePreference from '@root/javascript/common/hide-unavailable-preference';
const countryCodeForApi = (hideUnavailable) => (hideUnavailable ? 'AUTO' : '');
function streamingServiceLogo(url, styleName) {
    return `${url}/${styleName}.png`;
}
class AnimeWatchModalController extends Controller {
    constructor() {
        super(...arguments);
        _AnimeWatchModalController_handlebars.set(this, new HandlebarsCompilerFactory());
        _AnimeWatchModalController_animeId.set(this, null);
        _AnimeWatchModalController_animeTitle.set(this, null);
        _AnimeWatchModalController_isLoading.set(this, false);
        _AnimeWatchModalController_availabilityPreference.set(this, void 0);
    }
    static registerToApplication() {
        application.register(this.REGISTER_AS, this);
    }
    initialize() {
        __classPrivateFieldSet(this, _AnimeWatchModalController_availabilityPreference, new HideUnavailablePreference(this.availabilityPreferenceNamespace), "f");
    }
    get availabilityPreferenceNamespace() {
        return this.identifier;
    }
    connect() {
        this.unavailableToggleTarget.checked = this.hideUnavailable;
        if (this.data.has('animeId')) {
            this.animeId = this.data.get('animeId');
            this.animeTitle = this.data.get('animeTitle');
        }
    }
    toggleUnavailable() {
        __classPrivateFieldGet(this, _AnimeWatchModalController_availabilityPreference, "f").toggle();
        this.loadList();
    }
    async bodyTemplate() {
        return __classPrivateFieldGet(this, _AnimeWatchModalController_handlebars, "f").get('body', this.listTemplateTarget.textContent, {
            streamingServiceLogo(url, styleName) {
                return streamingServiceLogo(url, styleName);
            },
            streamingServiceLogoSrcSet: (url) => [
                `${streamingServiceLogo(url, 'small')} 1x`,
                `${streamingServiceLogo(url, 'large')} 2x`,
            ].join(', '),
        });
    }
    tryAgain() {
        this.loadList();
    }
    showMessage(errorMessage = null, canTryAgain = true) {
        this.messageBoxTarget.classList.remove('hide');
        this.listTarget.innerHTML = '';
        __classPrivateFieldGet(this, _AnimeWatchModalController_handlebars, "f").get('message', this.messageTemplateTarget.textContent).then((message) => {
            this.messageBoxTarget.innerHTML = message({
                hasMessage: !!errorMessage,
                errorMessage: typeof errorMessage === 'string' ? errorMessage : null,
                canTryAgain,
                allRegions: !this.hideUnavailable,
            });
        });
    }
    hideMessage() {
        this.messageBoxTarget.classList.add('hide');
    }
    get animeTitle() {
        return __classPrivateFieldGet(this, _AnimeWatchModalController_animeTitle, "f");
    }
    set animeTitle(value) {
        if (__classPrivateFieldGet(this, _AnimeWatchModalController_animeTitle, "f") !== value) {
            __classPrivateFieldSet(this, _AnimeWatchModalController_animeTitle, value, "f");
            this.titleTarget.title = value;
            this.titleTarget.textContent = value;
        }
    }
    set loading(value) {
        __classPrivateFieldSet(this, _AnimeWatchModalController_isLoading, value, "f");
        this.progressBarTarget.classList.toggle('visible', value);
        this.listTarget.classList.toggle('modal-content-loading', value);
        this.unavailableToggleTarget.disabled = value;
        if (value) {
            this.hideMessage();
        }
    }
    get animeId() {
        return __classPrivateFieldGet(this, _AnimeWatchModalController_animeId, "f");
    }
    set animeId(value) {
        __classPrivateFieldSet(this, _AnimeWatchModalController_animeId, value, "f");
        this.loadList();
    }
    get hideUnavailable() {
        return __classPrivateFieldGet(this, _AnimeWatchModalController_availabilityPreference, "f").enabled;
    }
    transformItems(items) {
        return items;
    }
}
_AnimeWatchModalController_handlebars = new WeakMap(), _AnimeWatchModalController_animeId = new WeakMap(), _AnimeWatchModalController_animeTitle = new WeakMap(), _AnimeWatchModalController_isLoading = new WeakMap(), _AnimeWatchModalController_availabilityPreference = new WeakMap();
AnimeWatchModalController.targets = [
    'title', 'unavailableToggle', 'list', 'listTemplate', 'progressBar',
    'messageBox', 'messageTemplate',
];
export const AnimeStreamsModalController = (_a = class extends AnimeWatchModalController {
        get availabilityPreferenceNamespace() {
            return 'streams';
        }
        get apiUrl() {
            return `/api/v1/anime/${this.animeId}/streams?country_code=${countryCodeForApi(this.hideUnavailable)}`;
        }
        loadList() {
            this.loading = true;
            Promise.all([
                this.bodyTemplate(),
                fetch(this.apiUrl).then((response) => response.json()),
            ])
                .then(([bodyTemplate, data]) => {
                if (data.items.length === 0) {
                    this.showMessage();
                }
                else {
                    this.listTarget.innerHTML = bodyTemplate({ items: this.transformItems(data.items) });
                }
            })
                .catch(() => {
                this.showMessage(true);
                this.listTarget.innerHTML = '';
            })
                .then(() => {
                this.loading = false;
            });
        }
    },
    _a.REGISTER_AS = 'anime-streams-modal',
    _a);
export const AnimeVideosModalController = (_b = class extends AnimeWatchModalController {
        constructor() {
            super(...arguments);
            _instances.add(this);
            _currentCategory.set(this, 'DEFAULT');
        }
        get availabilityPreferenceNamespace() {
            return 'videos';
        }
        loadList() {
            this.loading = true;
            // When logged in, we defer to the preference on their account.
            const defaultSchedules = window.Viewer.isLoggedIn ? undefined : window.Viewer.preferences.defaultReleaseSchedulesEnum;
            const currentCategory = __classPrivateFieldGet(this, _currentCategory, "f");
            const template = this.bodyTemplate();
            const query = graphqlClient.query({
                query: gql `
        query AnimeVideos(
          $animeId: ID!,
          $availableInViewerRegion: Boolean,
          $foldGroups: Boolean,
          $category: AnimeVideoCategory,
          $defaultSchedules: DefaultReleaseSchedulesOption
        ) {
          animeVideoCategories(animeId: $animeId) {
            defaultForViewer

            nodes {
              categoryDetails {
                label
              }

              category
            }
          }

          animeVideos(
            animeId: $animeId,
            availableInViewerRegion: $availableInViewerRegion,
            viewingPreferences: {
              defaultSchedules: $defaultSchedules
            },
            foldGroups: $foldGroups,
            category: $category,
            first: 100
          ) {
            nodes {
              url
              embedUrl
              title
              duration
              containsSpoilers
              spoilerNote

              video {
                title
                aspectRatio
                thumbnailUrl
                embeddable
                availableInViewerRegion
                liveStartTime
                liveEndTime
                uploadedAt

                tracks {
                  shortLabel
                  category
                  languageCode
                }
              }
            }
          }
        }
      `,
                variables: {
                    animeId: this.animeId,
                    availableInViewerRegion: this.hideUnavailable,
                    foldGroups: this.hideUnavailable,
                    category: currentCategory,
                    defaultSchedules: defaultSchedules
                },
            });
            Promise.all([
                template,
                query,
            ])
                .then(([bodyTemplate, graphqlResponse]) => {
                var _a;
                const data = graphqlResponse.data;
                const { nodes } = data.animeVideos;
                const categories = data.animeVideoCategories;
                __classPrivateFieldGet(this, _instances, "m", _showCategories).call(this, (_a = categories === null || categories === void 0 ? void 0 : categories.nodes) !== null && _a !== void 0 ? _a : [], currentCategory, categories.defaultForViewer);
                if (nodes.length === 0) {
                    this.showMessage();
                }
                else {
                    this.listTarget.innerHTML = bodyTemplate({ items: this.transformItems(nodes) });
                }
            }).catch((e) => {
                console.error(e);
                __classPrivateFieldGet(this, _instances, "m", _showCategories).call(this, []);
                this.showMessage(true);
                this.listTarget.innerHTML = '';
            })
                .finally(() => {
                this.loading = false;
            });
        }
        switchCategory() {
            __classPrivateFieldSet(this, _currentCategory, this.categoryChooserTarget.value || undefined, "f");
            this.loadList();
        }
        transformItems(items) {
            const now = Date.now();
            return items.map((animeVideo) => {
                const newVideo = { ...animeVideo.video };
                const newAnimeVideo = { ...animeVideo, video: newVideo };
                const audioTracks = [];
                const subtitleTracks = [];
                newAnimeVideo.displayTitle = animeVideo.title || newVideo.title;
                newVideo.audioTracks = audioTracks;
                newVideo.subtitleTracks = subtitleTracks;
                if (newVideo.liveStartTime) {
                    newVideo.isFuturePremiere = Date.parse(newVideo.liveStartTime) > now;
                }
                newVideo.tracks.forEach((track) => {
                    switch (track.category) {
                        case 'AURAL':
                            newVideo.hasTracks = true;
                            audioTracks.push(track);
                            break;
                        case 'TEXTUAL':
                            newVideo.hasTracks = true;
                            subtitleTracks.push(track);
                            break;
                        default: // do nothing
                    }
                });
                Object.freeze(newAnimeVideo);
                return newAnimeVideo;
            });
        }
    },
    _currentCategory = new WeakMap(),
    _instances = new WeakSet(),
    _showCategories = function _showCategories(categories, currentCategory, defaultForViewer) {
        const categoryChooserTarget = this.categoryChooserTarget;
        categoryChooserTarget.classList.toggle('invisible', categories.length < 2);
        if (categories.length === 0) {
            return;
        }
        const resolvedCurrentCategory = currentCategory === 'DEFAULT' ? defaultForViewer : currentCategory;
        const options = document.createDocumentFragment();
        categories.forEach((categoryOverview) => {
            const option = document.createElement('option');
            const categoryValue = categoryOverview.category;
            option.value = categoryValue || '';
            option.textContent = categoryOverview.categoryDetails.label;
            option.selected = resolvedCurrentCategory === categoryValue;
            options.appendChild(option);
        });
        this.categoryChooserTarget.replaceChildren(options);
    },
    _b.targets = [
        'categoryChooser', ...AnimeWatchModalController.targets
    ],
    _b.REGISTER_AS = 'anime-videos-modal',
    _b);
