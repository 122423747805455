import Duration from '@root/javascript/time/duration';
export default function (duration) {
    if (typeof duration === 'number') {
        duration = Duration.fromSeconds(duration);
    }
    else if (typeof duration === 'string') {
        duration = Duration.parse(duration);
    }
    if (duration instanceof Duration) {
        return duration.toString();
    }
    console.error('Invalid duration', duration);
    return '{invalid duration}';
}
