async function loadTimeZoneConversionModal() {
    const { default: TimeZoneConversionModal } = await import(/* webpackChunkName: "time_zone_conversion_modal" */ '@root/modal/time-zone-conversion-modal');
    return TimeZoneConversionModal;
}
async function showTimeZoneConversionModal(when, label) {
    const TimeZoneConversionModal = await loadTimeZoneConversionModal();
    TimeZoneConversionModal.show(when, label);
}
export default loadTimeZoneConversionModal;
export { loadTimeZoneConversionModal, showTimeZoneConversionModal, };
