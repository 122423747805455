var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var _AnimeLinksModal_controllerRegistered;
import AnimeLinksModalController from 'controllers/anime-links-modal-controller';
import { addImportedModal } from 'util/modal-utils';
class AnimeLinksModal {
    constructor() {
        _AnimeLinksModal_controllerRegistered.set(this, false);
    }
    show(animeId, animeTitle) {
        if (!__classPrivateFieldGet(this, _AnimeLinksModal_controllerRegistered, "f")) {
            __classPrivateFieldSet(this, _AnimeLinksModal_controllerRegistered, true, "f");
            AnimeLinksModalController.registerToApplication();
        }
        import(/* webpackChunkName: "anime_links_modal" */ 'templates/anime_links_modal.handlebars')
            .then(addImportedModal((element) => {
            const { dataset } = element.firstChild;
            dataset.animeLinksModalAnimeId = `${animeId}`;
            dataset.animeLinksModalAnimeTitle = `${animeTitle}`;
        }));
    }
}
_AnimeLinksModal_controllerRegistered = new WeakMap();
export default new AnimeLinksModal();
