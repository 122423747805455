// See: https://stackoverflow.com/questions/14934089/convert-iso-8601-duration-with-javascript/29153059
// eslint-disable-next-line max-len
// /(-)?P(?:([.,\d]+)Y)?(?:([.,\d]+)M)?(?:([.,\d]+)W)?(?:([.,\d]+)D)?T(?:([.,\d]+)H)?(?:([.,\d]+)M)?(?:([.,\d]+)S)?/
import zpad from '@root/javascript/util/text/zpad';
const iso8601DurationRegex = /(-)?P(?:((?:-|\+)?[.,\d]+)Y)?(?:((?:-|\+)?[.,\d]+)M)?(?:((?:-|\+)?[.,\d]+)W)?(?:((?:-|\+)?[.,\d]+)D)?(?:T(?:((?:-|\+)?[.,\d]+)H)?(?:((?:-|\+)?[.,\d]+)M)?(?:((?:-|\+)?[.,\d]+)S)?)?/;
var Part;
(function (Part) {
    Part[Part["Years"] = 7] = "Years";
    Part[Part["Months"] = 6] = "Months";
    Part[Part["Weeks"] = 5] = "Weeks";
    Part[Part["Days"] = 4] = "Days";
    Part[Part["Hours"] = 3] = "Hours";
    Part[Part["Minutes"] = 2] = "Minutes";
    Part[Part["Seconds"] = 1] = "Seconds";
})(Part || (Part = {}));
const SECONDS_PER_WEEK = 604800;
const SECONDS_PER_DAY = 86400;
const SECONDS_PER_HOUR = 3600;
const SECONDS_PER_MINUTE = 60;
class Duration {
    constructor(negative, years, months, weeks, days, hours, minutes, seconds) {
        this.negative = negative;
        this.years = years;
        this.months = months;
        this.weeks = weeks;
        this.days = days;
        this.hours = hours;
        this.minutes = minutes;
        this.seconds = seconds;
    }
    isEmpty() {
        return this.years === 0 && this.months === 0 && this.weeks === 0 && this.days === 0 && this.hours === 0 && this.minutes === 0 && this.seconds === 0;
    }
    add(changes = {}) {
        var _a, _b, _c, _d, _e, _f, _g;
        return new Duration(this.negative, this.years + ((_a = changes.years) !== null && _a !== void 0 ? _a : 0), this.months + ((_b = changes.months) !== null && _b !== void 0 ? _b : 0), this.weeks + ((_c = changes.weeks) !== null && _c !== void 0 ? _c : 0), this.days + ((_d = changes.days) !== null && _d !== void 0 ? _d : 0), this.hours + ((_e = changes.hours) !== null && _e !== void 0 ? _e : 0), this.minutes + ((_f = changes.minutes) !== null && _f !== void 0 ? _f : 0), this.seconds + ((_g = changes.seconds) !== null && _g !== void 0 ? _g : 0));
    }
    subtract(changes = {}) {
        var _a, _b, _c, _d, _e, _f, _g;
        return new Duration(this.negative, this.years - ((_a = changes.years) !== null && _a !== void 0 ? _a : 0), this.months - ((_b = changes.months) !== null && _b !== void 0 ? _b : 0), this.weeks - ((_c = changes.weeks) !== null && _c !== void 0 ? _c : 0), this.days - ((_d = changes.days) !== null && _d !== void 0 ? _d : 0), this.hours - ((_e = changes.hours) !== null && _e !== void 0 ? _e : 0), this.minutes - ((_f = changes.minutes) !== null && _f !== void 0 ? _f : 0), this.seconds - ((_g = changes.seconds) !== null && _g !== void 0 ? _g : 0));
    }
    with(changes = {}) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        return new Duration((_a = changes.negative) !== null && _a !== void 0 ? _a : this.negative, (_b = changes.years) !== null && _b !== void 0 ? _b : this.years, (_c = changes.months) !== null && _c !== void 0 ? _c : this.months, (_d = changes.weeks) !== null && _d !== void 0 ? _d : this.weeks, (_e = changes.days) !== null && _e !== void 0 ? _e : this.days, (_f = changes.hours) !== null && _f !== void 0 ? _f : this.hours, (_g = changes.minutes) !== null && _g !== void 0 ? _g : this.minutes, (_h = changes.seconds) !== null && _h !== void 0 ? _h : this.seconds);
    }
    toDurationLike() {
        const modifier = this.negative ? -1 : 1;
        return {
            years: modifier * this.years,
            months: modifier * this.months,
            weeks: modifier * this.weeks,
            days: modifier * this.days,
            hours: modifier * this.hours,
            minutes: modifier * this.minutes,
            seconds: modifier * this.seconds,
        };
    }
    toIsoString() {
        const parts = [];
        const timeParts = [];
        function addPart(part, identifier, destination) {
            if (part !== 0) {
                destination.push(`${part}`, identifier);
            }
        }
        addPart(this.years, 'Y', parts);
        addPart(this.months, 'M', parts);
        addPart(this.weeks, 'W', parts);
        addPart(this.days, 'D', parts);
        addPart(this.hours, 'H', timeParts);
        addPart(this.minutes, 'M', timeParts);
        addPart(this.seconds, 'S', timeParts);
        if (parts.length === 0 && timeParts.length === 0) {
            return 'PT0S';
        }
        const result = [];
        if (this.negative) {
            result.push('-');
        }
        result.push('P');
        if (parts.length > 0) {
            result.push(...parts);
        }
        if (timeParts.length > 0) {
            result.push('T', ...timeParts);
        }
        return result.join('');
    }
    toString() {
        const parts = [];
        function addPart(item) {
            if (parts.length > 0) {
                parts.push(zpad(item));
            }
            else {
                parts.push(`${item}`);
            }
        }
        if (this.hours > 0) {
            addPart(this.hours);
        }
        addPart(this.minutes);
        addPart(this.seconds);
        return parts.join(':');
    }
    toSeconds() {
        if (this.years > 0) {
            throw 'Cannot convert to seconds when months are present';
        }
        if (this.months > 0) {
            throw 'Cannot convert to seconds when months are present';
        }
        let amount = this.seconds;
        amount += this.weeks * SECONDS_PER_WEEK;
        amount += this.days * SECONDS_PER_DAY;
        amount += this.hours * SECONDS_PER_HOUR;
        amount += this.minutes * SECONDS_PER_MINUTE;
        return amount * (this.negative ? -1 : 1);
    }
    normalize() {
        if (this.canBeNormalized()) {
            return Duration.fromSeconds(this.toSeconds());
        }
        return this;
    }
    canBeNormalized() {
        const abs = Math.abs;
        if (abs(this.days) >= 7) {
            return true;
        }
        if (abs(this.hours) >= 24) {
            return true;
        }
        if (abs(this.minutes) >= 60) {
            return true;
        }
        if (abs(this.seconds) >= 60) {
            return true;
        }
        return false;
    }
    static empty() {
        return new Duration(false, 0, 0, 0, 0, 0, 0, 0);
    }
    static fromSeconds(seconds) {
        return new Duration(seconds < 0, 0, 0, (seconds / SECONDS_PER_WEEK) | 0, (seconds / SECONDS_PER_DAY) % 7 | 0, (seconds / SECONDS_PER_HOUR) % 24 | 0, (seconds / SECONDS_PER_MINUTE) % 60 | 0, seconds % 60 | 0);
    }
    static parse(iso8601Duration) {
        const matches = iso8601Duration.match(iso8601DurationRegex);
        if (!matches) {
            return this.empty();
        }
        return new Duration(matches[1] !== undefined, matches[2] === undefined ? 0 : Number(matches[2]), matches[3] === undefined ? 0 : Number(matches[3]), matches[4] === undefined ? 0 : Number(matches[4]), matches[5] === undefined ? 0 : Number(matches[5]), matches[6] === undefined ? 0 : Number(matches[6]), matches[7] === undefined ? 0 : Number(matches[7]), matches[8] === undefined ? 0 : Number(matches[8]));
    }
}
export default Duration;
export { Part };
