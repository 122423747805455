var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _AnimeLinksModalController_handlebars, _AnimeLinksModalController_loading, _AnimeLinksModalController_animeId, _AnimeLinksModalController_animeTitle;
import HandlebarsCompilerFactory from '@root/javascript/handlebars/compiler-factory';
import { application } from '@local-stimulus';
import ModalController from './global/modal-controller';
class AnimeLinksModalController extends ModalController {
    constructor() {
        super(...arguments);
        _AnimeLinksModalController_handlebars.set(this, new HandlebarsCompilerFactory());
        _AnimeLinksModalController_loading.set(this, false);
        _AnimeLinksModalController_animeId.set(this, null);
        _AnimeLinksModalController_animeTitle.set(this, null);
    }
    static registerToApplication() {
        application.register('anime-links-modal', this);
    }
    connect() {
        __classPrivateFieldSet(this, _AnimeLinksModalController_animeId, this.data.get('animeId'), "f");
        __classPrivateFieldSet(this, _AnimeLinksModalController_animeTitle, this.data.get('animeTitle'), "f");
        this.titleTarget.title = __classPrivateFieldGet(this, _AnimeLinksModalController_animeTitle, "f");
        this.titleTarget.textContent = __classPrivateFieldGet(this, _AnimeLinksModalController_animeTitle, "f");
        this.loadLinks();
        this.open();
    }
    close() {
        super.close();
        this.element.remove();
    }
    loadLinks() {
        this.loading = true;
        Promise.all([
            __classPrivateFieldGet(this, _AnimeLinksModalController_handlebars, "f").get(`${this.identifier}:body`, this.bodyTemplateTarget.textContent),
            fetch(this.apiUrl).then((response) => response.json()),
        ])
            .then(([bodyTemplate, data]) => {
            this.bodyTarget.innerHTML = bodyTemplate({
                anime: data,
                linkPreferences: window.Viewer.linkPreferences,
            });
        })
            .catch(() => {
            this.showMessage();
            this.bodyTarget.innerHTML = '';
        })
            .then(() => {
            this.loading = false;
        });
    }
    tryAgain() {
        this.loadLinks();
    }
    showMessage() {
        this.messageBoxTarget.classList.remove('hide');
        this.bodyTarget.innerHTML = '';
        __classPrivateFieldGet(this, _AnimeLinksModalController_handlebars, "f").get('message', this.messageTemplateTarget.textContent)
            .then((message) => {
            this.messageBoxTarget.innerHTML = message({});
        });
    }
    hideMessage() {
        this.messageBoxTarget.classList.add('hide');
    }
    set loading(value) {
        __classPrivateFieldSet(this, _AnimeLinksModalController_loading, value, "f");
        const classListMethodName = value ? 'add' : 'remove';
        this.progressBarTarget.classList[classListMethodName]('visible');
        this.bodyTarget.classList[classListMethodName]('modal-content-loading');
        if (value) {
            this.hideMessage();
        }
    }
    get apiUrl() {
        return `/api/v1/anime/${__classPrivateFieldGet(this, _AnimeLinksModalController_animeId, "f")}`;
    }
}
_AnimeLinksModalController_handlebars = new WeakMap(), _AnimeLinksModalController_loading = new WeakMap(), _AnimeLinksModalController_animeId = new WeakMap(), _AnimeLinksModalController_animeTitle = new WeakMap();
AnimeLinksModalController.targets = [
    'title', 'body', 'bodyTemplate', 'titleTemplate', 'progressBar',
    'messageBox', 'messageTemplate',
];
export default AnimeLinksModalController;
