import { replaceQueryParam } from '@root/javascript/util/url-utils';
import { application, Controller } from '@local-stimulus';
class TitlesOptionController extends Controller {
    save() {
        const newValue = this.selectTarget.value;
        const { location } = window;
        window.Viewer.preferences.set('titles', newValue);
        location.href = replaceQueryParam(location.href, 'titles', newValue);
    }
}
TitlesOptionController.targets = ['select'];
application.register('titles-option', TitlesOptionController);
export default TitlesOptionController;
