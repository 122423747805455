var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var _default_1_instances, _default_1_dimensions_get, _default_1_aspectRatio_get, _default_1_loading_set;
import { application, Controller } from '@local-stimulus';
import { replaceQueryParams } from '@root/javascript/util/url-utils';
import { addImportedModal } from 'util/modal-utils';
const IDENTIFIER = 'video-overlay';
const AUTOPLAY_PARAM = 'autoplay';
const API_PARAM = 'api';
const ENABLEJSAPI_PARAM = 'enablejsapi';
let REGISTERED = false;
export default class default_1 extends Controller {
    constructor() {
        super(...arguments);
        _default_1_instances.add(this);
    }
    static registerToApplication() {
        if (!REGISTERED) {
            REGISTERED = true;
            application.register(IDENTIFIER, this);
        }
    }
    static show(videoSrc, thumbnailUrl, aspectRatio) {
        this.registerToApplication();
        import(/* webpackChunkName: "video_overlay_template" */ 'templates/video_overlay.handlebars')
            .then(addImportedModal((element) => {
            const { dataset } = element.firstChild;
            dataset.videoOverlaySrc = videoSrc;
            dataset.videoOverlayThumbnailUrl = thumbnailUrl;
            dataset.videoOverlayAspectRatio = (aspectRatio || '');
        }));
    }
    connect() {
        this.addPlayer();
    }
    close() {
        this.element.remove();
    }
    resizePlayer() {
        if (this.hasIframeTarget) {
            const { width, height } = __classPrivateFieldGet(this, _default_1_instances, "a", _default_1_dimensions_get);
            const iframeStyle = this.iframeTarget.style;
            iframeStyle.width = `${width}px`;
            iframeStyle.height = `${height}px`;
        }
    }
    addPlayer() {
        if (this.hasIframeTarget) {
            return;
        }
        __classPrivateFieldSet(this, _default_1_instances, true, "a", _default_1_loading_set);
        const { width, height } = __classPrivateFieldGet(this, _default_1_instances, "a", _default_1_dimensions_get);
        let heightModifier = 0;
        const iframe = document.createElement('iframe');
        const iframeStyle = iframe.style;
        const url = this.data.get('src');
        const params = {
        // origin: 'www.livechart.me',
        // rel: 0,
        // [AUTOPLAY_PARAM]: 1,
        // [API_PARAM]: 'postMessage',
        // [ENABLEJSAPI_PARAM]: 1,
        };
        if (url.includes('youtube.com')) {
            params.origin = 'www.livechart.me';
            params.rel = 0;
            params[AUTOPLAY_PARAM] = 1;
            params[API_PARAM] = 'postMessage';
            params[ENABLEJSAPI_PARAM] = 1;
        }
        else if (url.includes('facebook.com')) {
            params[AUTOPLAY_PARAM] = true;
            params.mute = 0;
            params.locale = 'en_US';
            params.sdk = 'joey';
            params.container_width = width;
        }
        else if (url.includes('platform.twitter.com')) {
            params.width = width;
            params.maxWidth = width;
            heightModifier += 1;
        }
        iframe.onload = () => { __classPrivateFieldSet(this, _default_1_instances, false, "a", _default_1_loading_set); };
        iframe.allowFullscreen = true;
        iframe.setAttribute(`data-${this.identifier}-target`, 'iframe');
        iframe.scrolling = 'no';
        iframe.src = replaceQueryParams(url, params);
        iframeStyle.width = `${width}px`;
        iframeStyle.height = `${height + heightModifier}px`;
        iframeStyle.backgroundImage = `url(${this.data.get('thumbnailUrl')})`;
        iframeStyle.colorScheme = 'normal';
        iframeStyle.overflow = 'hidden';
        this.iframeContainerTarget.appendChild(iframe);
    }
}
_default_1_instances = new WeakSet(), _default_1_dimensions_get = function _default_1_dimensions_get() {
    const padding = 50;
    const aspectRatio = __classPrivateFieldGet(this, _default_1_instances, "a", _default_1_aspectRatio_get);
    const maxHeight = window.innerHeight - (padding * 2);
    const maxWidth = window.innerWidth - (padding * 2);
    let width = maxWidth;
    let height = maxWidth / aspectRatio;
    if (height > maxHeight) {
        height = maxHeight;
        width = maxHeight * aspectRatio;
    }
    return {
        height: Math.round(height),
        width: Math.round(width),
    };
}, _default_1_aspectRatio_get = function _default_1_aspectRatio_get() {
    const aspectRatioValue = this.data.get('aspectRatio');
    return aspectRatioValue ? +aspectRatioValue : (16 / 9);
}, _default_1_loading_set = function _default_1_loading_set(value) {
    this.progressBarTarget.classList[value ? 'add' : 'remove']('visible');
};
default_1.targets = ['iframeContainer', 'iframe', 'progressBar'];
