import { addImportedModal } from 'util/modal-utils';
class LibraryModal {
    show(animeId, animeTitle) {
        return import(/* webpackPrefetch: true */ 'templates/library_editor_modal.handlebars')
            .then(addImportedModal((element) => {
            const { dataset } = element.firstChild;
            dataset.libraryEditorAnimeId = `${animeId}`;
            dataset.libraryEditorAnimeTitle = animeTitle;
        }));
    }
}
export default new LibraryModal();
