var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _default_1_instances, _default_1_liveStartTime, _default_1_liveEndTime, _default_1_uploadedAt, _default_1_duration, _default_1_timeoutManager, _default_1_newBadge, _default_1_updateLivenessUI, _default_1_scheduleDurationBadgeUpdate;
import { application, Controller } from '@local-stimulus';
import VideoOverlayController from 'controllers/video-overlay-controller';
import TimeoutManager from '@root/javascript/util/timeout-manager';
import formatVideoDuration from '@root/javascript/util/text/format-video-duration';
import Duration from '@root/javascript/time/duration';
const DURATION_BADGE_REFRESH_KEY = 'video:duration-badge-refresh';
const NEWNESS_THRESHOLD_DAYS = 7;
const NEWNESS_THRESHOLD_MILLIS = NEWNESS_THRESHOLD_DAYS * 24 * 60 * 60 * 1000;
const NEWNESS_INDICATOR_NAME = 'newnessIndicator';
export default class default_1 extends Controller {
    constructor() {
        super(...arguments);
        _default_1_instances.add(this);
        _default_1_liveStartTime.set(this, null);
        _default_1_liveEndTime.set(this, null);
        _default_1_uploadedAt.set(this, void 0);
        _default_1_duration.set(this, null);
        _default_1_timeoutManager.set(this, new TimeoutManager());
    }
    static registerToApplication() {
        application.register('video', this);
    }
    connect() {
        __classPrivateFieldSet(this, _default_1_uploadedAt, Date.parse(this.data.get('uploadedAt')), "f");
        const liveStartTimeStr = this.data.get('liveStartTime');
        const liveEndTimeStr = this.data.get('liveEndTime');
        const durationStr = this.data.get('duration');
        if (durationStr) {
            __classPrivateFieldSet(this, _default_1_duration, Duration.parse(durationStr), "f");
        }
        if (liveStartTimeStr) {
            __classPrivateFieldSet(this, _default_1_liveStartTime, Date.parse(liveStartTimeStr), "f");
        }
        if (liveEndTimeStr) {
            __classPrivateFieldSet(this, _default_1_liveEndTime, Date.parse(liveEndTimeStr), "f");
        }
        __classPrivateFieldGet(this, _default_1_instances, "m", _default_1_updateLivenessUI).call(this);
        this.updateNewnessIndicator();
    }
    disconnect() {
        __classPrivateFieldGet(this, _default_1_timeoutManager, "f").cancelAll();
    }
    updateNewnessIndicator() {
        const now = Date.now();
        const uploadedRecently = __classPrivateFieldGet(this, _default_1_uploadedAt, "f") > (now - NEWNESS_THRESHOLD_MILLIS);
        if (uploadedRecently) {
            if (!this.hasNewnessIndicatorTarget) {
                const element = __classPrivateFieldGet(this, _default_1_instances, "m", _default_1_newBadge).call(this, 'NEW', NEWNESS_INDICATOR_NAME, {
                    title: `Uploaded in the past ${NEWNESS_THRESHOLD_DAYS} days`,
                });
                this.badgesTarget.prepend(element);
                __classPrivateFieldGet(this, _default_1_timeoutManager, "f").scheduleAt(NEWNESS_INDICATOR_NAME, this.updateNewnessIndicator.bind(this), now + NEWNESS_THRESHOLD_MILLIS);
            }
        }
        else if (this.hasNewnessIndicatorTarget) {
            this.newnessIndicatorTarget.remove();
            __classPrivateFieldGet(this, _default_1_timeoutManager, "f").cancel(NEWNESS_INDICATOR_NAME);
        }
    }
    openPlayer(e) {
        const embedUrl = this.data.get('embedUrl');
        if (embedUrl) {
            e.preventDefault();
            VideoOverlayController.show(embedUrl, this.data.get('thumbnailUrl'), this.data.get('aspectRatio'));
        }
        const { gtag } = window;
        if (typeof gtag !== 'undefined') {
            gtag('event', 'click', {
                event_category: 'Anime Video',
                event_label: this.element.href,
            });
        }
    }
}
_default_1_liveStartTime = new WeakMap(), _default_1_liveEndTime = new WeakMap(), _default_1_uploadedAt = new WeakMap(), _default_1_duration = new WeakMap(), _default_1_timeoutManager = new WeakMap(), _default_1_instances = new WeakSet(), _default_1_newBadge = function _default_1_newBadge(text, targetName, { title = null, } = {}) {
    const element = document.createElement('span');
    element.classList.add('video--badge');
    element.dataset.target = `${this.identifier}.${targetName}`;
    element.textContent = text;
    if (title) {
        element.title = title;
    }
    return element;
}, _default_1_updateLivenessUI = function _default_1_updateLivenessUI() {
    let isLive = false;
    let text = null;
    if (__classPrivateFieldGet(this, _default_1_liveStartTime, "f")) {
        const now = Date.now();
        if (this.hasPremiereTimeTarget && __classPrivateFieldGet(this, _default_1_liveStartTime, "f") < now) {
            this.premiereTimeTarget.remove();
        }
        if (__classPrivateFieldGet(this, _default_1_liveStartTime, "f") > now) {
            text = 'UPCOMING';
            __classPrivateFieldGet(this, _default_1_instances, "m", _default_1_scheduleDurationBadgeUpdate).call(this, __classPrivateFieldGet(this, _default_1_liveStartTime, "f"));
        }
        else if (!__classPrivateFieldGet(this, _default_1_liveEndTime, "f") || __classPrivateFieldGet(this, _default_1_liveEndTime, "f") > now) {
            // We assume the video is currently live if we don't know the end time.
            text = 'LIVE';
            isLive = true;
            if (__classPrivateFieldGet(this, _default_1_liveEndTime, "f")) {
                __classPrivateFieldGet(this, _default_1_instances, "m", _default_1_scheduleDurationBadgeUpdate).call(this, __classPrivateFieldGet(this, _default_1_liveEndTime, "f"));
            }
        }
    }
    const duration = __classPrivateFieldGet(this, _default_1_duration, "f");
    const { durationBadgeTarget } = this;
    const durationBadgeClasslist = durationBadgeTarget.classList;
    durationBadgeClasslist.toggle('-live', isLive);
    if (text === null && (duration === null || duration.isEmpty())) {
        durationBadgeClasslist.add('hide');
    }
    else {
        durationBadgeTarget.textContent = text || formatVideoDuration(__classPrivateFieldGet(this, _default_1_duration, "f"));
    }
}, _default_1_scheduleDurationBadgeUpdate = function _default_1_scheduleDurationBadgeUpdate(whenMillis) {
    __classPrivateFieldGet(this, _default_1_timeoutManager, "f").scheduleAt(DURATION_BADGE_REFRESH_KEY, __classPrivateFieldGet(this, _default_1_instances, "m", _default_1_updateLivenessUI).bind(this), whenMillis);
};
default_1.targets = ['durationBadge', 'badges', NEWNESS_INDICATOR_NAME, 'premiereTime'];
